// extracted by mini-css-extract-plugin
export var articleWrapper = "l_pw";
export var articleText = "l_px d_dt d_cr";
export var header = "l_py d_Y d_v";
export var headerImageWrapper = "l_pz d_bc d_Z d_7 d_6 d_3 d_8 d_bj d_bQ";
export var headerGradient = "l_pB d_bc d_Z d_7 d_6 d_3 d_8";
export var headerGradientOverlay = "l_pC d_bc d_Z d_7 d_6 d_3 d_8";
export var headerContentWrapper = "l_pD d_by d_bN d_bC d_bH d_bk d_Y";
export var headerContentWrapperAlt = "l_pF d_by d_bN d_bC d_bH d_bk d_Y";
export var contentWrapper = "l_pG d_v";
export var dateTag = "l_pH d_cd d_cq d_dp d_v";
export var icon1 = "l_pJ d_cN";
export var icon2 = "l_pK d_cN d_cH";
export var tagBtn = "l_pL d_b0 d_cC d_dn d_dz";
export var headerText = "l_pM d_v d_cd d_cr d_bV";
export var center = "l_pN d_dv";
export var videoIframeStyle = "l_pP d_d4 d_v d_G d_bx d_b0 d_Q";
export var articleImageWrapper = "l_pQ d_cr d_Y";
export var articleImageWrapperIcon = "l_pR d_cr d_Y";
export var articleRow = "l_pS d_bC";
export var quoteWrapper = "l_gP d_bB d_bN d_cr";
export var quoteBar = "l_pT d_G";
export var quoteText = "l_pV";
export var authorBox = "l_pW d_v";
export var authorRow = "l_pX d_bC d_bV d_cx";
export var separator = "l_pY d_v";
export var line = "l_fj d_v d_fj d_cX d_cx";
export var authorImage = "l_ln d_b6 d_Y d_v d_cs";
export var authorText = "l_pZ d_cg d_cs";
export var masonryImageWrapper = "l_p0";
export var bottomSeparator = "l_p1 d_v d_cx";
export var linksWrapper = "l_p2 d_cg d_dt";
export var comments = "l_p3 d_bC d_cx";
export var sharing = "l_p4 d_by d_bC d_bH";
export var shareText = "l_p5 d_v d_dv";
export var icon = "l_p6";
export var customRow = "l_p7 d_bC d_Y";
export var text = "l_p8";
export var SubtitleSmall = "l_p9";
export var SubtitleNormal = "l_qb";
export var SubtitleLarge = "l_qc";