// extracted by mini-css-extract-plugin
export var articleWrapper = "p_pw";
export var searchResult = "p_qL p_pw";
export var line = "p_fj d_fj d_cX";
export var articleTitle = "p_qM d_cp";
export var articleText = "p_px d_cr";
export var authorImage = "p_ln d_ln d_b6 d_Y d_cs";
export var authorText = "p_pZ d_cg d_cs";
export var textCenter = "p_dv d_dv";
export var searchWrapper = "p_qN d_by d_bN d_dq d_ck d_Y";
export var searchInput = "p_qP d_cD d_v d_b3";
export var searchBtn = "p_qQ d_b0 d_bB d_bN d_Z d_cC d_dz";
export var text = "p_p8 d_cq";
export var elementWrapper = "p_mw d_ck";
export var select = "p_qR d_v d_b3";
export var articleImageWrapper = "p_pQ d_cr d_Y d_T";
export var articleImageWrapperIcon = "p_pR d_cr d_Y d_T";
export var articleImageWrapperColumns = "p_qS d_cr d_fg d_Y";
export var contentPadding = "p_qT d_cR";
export var otherPadding = "p_qV d_cL";
export var paginationWrapper = "p_qW";
export var pagination = "p_qX";
export var disabledPagination = "p_qY";
export var breakMe = "p_qZ";
export var activePage = "p_q0";
export var next = "p_q1";
export var pages = "p_q2";
export var searchTitle = "p_q3 d_cq";
export var categoryTitle = "p_q4 d_cq d_Y";
export var searchText = "p_q5 d_cs";
export var bold = "p_q6";
export var field = "p_q7";
export var dateTag = "p_pH d_k d_cq d_dp";
export var icon1 = "p_pJ d_cN";
export var icon2 = "p_pK d_cN d_cH";
export var tagBtn = "p_pL d_b0 d_cC d_dn d_dz";
export var linksWrapper = "p_p2 d_cg d_dv";
export var header = "p_py d_v d_Y";
export var headerImage = "p_q8 d_bc d_Z d_7 d_6 d_3 d_8";
export var separator = "p_pY d_cX";
export var resultImage = "p_q9";