// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_r4 d_gt d_cr";
export var heroHeaderCenter = "t_gv d_gv d_cr d_dv";
export var heroHeaderRight = "t_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "t_r5 d_gq d_cv";
export var heroParagraphCenter = "t_gr d_gr d_cv d_dv";
export var heroParagraphRight = "t_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "t_r6 d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "t_r7 d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "t_r8 d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "t_r9 d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "t_sb d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "t_sc x_sc";
export var heroExceptionNormal = "t_sd x_sd";
export var heroExceptionLarge = "t_sf x_sf";
export var Title1Small = "t_sg x_sg x_ss x_st";
export var Title1Normal = "t_sh x_sh x_ss x_sv";
export var Title1Large = "t_sj x_sj x_ss x_sw";
export var BodySmall = "t_sk x_sk x_ss x_sM";
export var BodyNormal = "t_sl x_sl x_ss x_sN";
export var BodyLarge = "t_sm x_sm x_ss x_sP";