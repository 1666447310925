// extracted by mini-css-extract-plugin
export var alignDiscLeft = "r_rp d_fn d_bF d_dt";
export var alignLeft = "r_qf d_fn d_bF d_dt";
export var alignDiscCenter = "r_rq d_fp d_bC d_dv";
export var alignCenter = "r_bN d_fp d_bC d_dv";
export var alignDiscRight = "r_rr d_fq d_bG d_dw";
export var alignRight = "r_qg d_fq d_bG d_dw";
export var footerContainer = "r_rs d_dV d_bV";
export var footerContainerFull = "r_rt d_dS d_bV";
export var footerHeader = "r_kd d_kd";
export var footerTextWrapper = "r_rv d_v";
export var footerDisclaimerWrapper = "r_kl d_kl d_cj";
export var badgeWrapper = "r_rw d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "r_rx undefined";
export var footerDisclaimerLeft = "r_ry undefined";
export var verticalTop = "r_rz d_by d_bH d_bM d_bK";
export var firstWide = "r_rB";
export var disclaimer = "r_rC d_by d_bH";
export var socialDisclaimer = "r_rD";
export var left = "r_rF";
export var wide = "r_rG d_cx";
export var right = "r_rH d_bJ";
export var line = "r_fj d_fk d_ct";
export var badgeDisclaimer = "r_rJ d_bB d_bN d_bH";
export var badgeContainer = "r_rK d_by d_bG d_bN";
export var badge = "r_rL";
export var padding = "r_rM d_c6";
export var end = "r_rN d_bG";
export var linkWrapper = "r_rP d_dz";
export var link = "r_mB d_dz";
export var colWrapper = "r_rQ d_cw";
export var consent = "r_f d_f d_bB d_bN";
export var media = "r_rR d_bw d_dx";
export var itemRight = "r_rS";
export var itemLeft = "r_rT";
export var itemCenter = "r_rV";
export var exceptionWeight = "r_rW x_sV";